module.exports = function () {
    function init() {
        $('.loadEmail').on('click', function () {
            let $email = $(this).parent().parent();
            let $node = $('.bodyEmail');
            $($node).empty();
            $node.append($email.data('prototype'));

        });

        ModuleComponents.init();
    }

    return {
        init: function (params) {
            init(params);
        }
    };
}();
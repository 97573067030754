import('../theme/downloaded/passtrenght/passtrenght.css');
module.exports = function () {
    function init() {
        $('#change_password_newPassword_first').passtrength({
            minChars: 8,
            textWeak: "Slabé heslo",
            textMedium: "Stredne silné heslo",
            textStrong: "Silné heslo",
            textVeryStrong: "Veľmi silné heslo",
            passwordToggle: true
        });
        checkPasswordComplexity();
    }

    function checkPasswordComplexity() {
        $('#change_password_newPassword_first').on('change', function () {
            let lang = window.location.href.split('/');
            let language = lang[lang.length-1];
            if (language.length <= 2){
                lang = language;
            }else{
                lang = 'sk';
            }

            let $request = $.ajax({
                url: '/user/ajax/checkpasswordcomplexity/'+lang,
                type: 'POST',
                data: {'newPassword': document.getElementById('change_password_newPassword_first').value},
                async: true
            });
            $request.done(function (response) {
                response = JSON.parse(response);
                if (response.data.length !== 0) {
                    $('.check-password-complexity').empty();
                    $('.check-password-complexity').append("" +
                    "<div class='alert alert-danger'><lu class='errors'>");
                    for (let passWdErr in response.data) {
                        $('.errors').append("<li class=''>" + response.data[passWdErr] + "</li>");
                    }
                    $('.check-password-complexity').append("</ul></div>");
                }else{
                    $('.check-password-complexity').empty();
                }
            });
        });
    }

    return {
        init: function () {
            init();
        }
    };
}();
module.exports = function () {

    let tableTickets = null;

    function init(params) {
        tableTickets = $("#table-tickets-all").DataTable({
            retrieve: true,
            language: {
                url: "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Slovak.json"
            },
            ajax: {
                url: params.ticketsDataUrl,
                dataSrc: 'data',
                type: "GET",
            },
            columnDefs: [
                {orderable: true, targets: 0},
                {orderable: true, targets: 1},
                {orderable: false, targets: 2},
                {orderable: false, targets: 3},
                {orderable: false, targets: 4},
                {orderable: false, targets: 5},
                {orderable: false, targets: 6},
            ],
            order: [[1, 'asc']],
            processing: true,
            serverSide: true,
            responsive: true,
            initComplete: function (settings, json) {
                $('[data-toggle="tooltip"]').tooltip({boundary: 'window'});
            }
        });

        $('#collapse').click(function () {
            $('#emailList').toggleClass("fa-angle-down fa-angle-right");
        });

        $('#collapse2').click(function () {
            $('#ticketRedmine').toggleClass("fa-angle-right fa-angle-down");
        });

        $('#showResponseForm').on('shown.bs.modal', function () {
            $('#myInput').trigger('focus');
        });
        $('.loadEmail').on('click', function () {
            let $email = $(this).parent().parent();
            let $tableRow = $email.children();
            let emailId = $email.data('value');
            if ($tableRow[0].innerHTML !== '') {
                sendReaded(emailId);
                $($tableRow[0]).empty();
                $($tableRow[0]).append('');
            }
            let $node = $('.bodyEmail');
            let link = document.getElementById('response').href;
            let arrayLink = link.split('/');
            link = link.replace(arrayLink[arrayLink.length - 1], emailId);
            document.getElementById('response').href = link;
            $($node).empty();
            $node.append($email.data('prototype'));

        });

        if ($("#redmineTicket").data('content')) {
            $.get("/helpdesk/ajax/get/redmine/ticket/" + $("#redmineTicket").data('content'), function (data, status) {
                data = JSON.parse(data);
                var state = '';
                if (data.issue.status.id === 5) {
                    state = 'badge badge-primary';
                } else {
                    state = 'badge badge-danger';
                }
                $(".redmineBadge").text(data.issue.status.name);
                $(".redmineBadge").addClass(state);
                $('.ticketName').append('[' + data.issue.subject + ']');
                let notes = "";
                for (let i = 0; i < data.issue.journals.length; i++) {
                    if (data.issue.journals[i].notes !== '') {
                        notes = notes.concat(
                            "<li class='list-group-item'>" +
                            "<p class='text-left'>" + data.issue.journals[i].notes + "</p>" +
                            "<p class='text-right'>" + data.issue.journals[i].user.name + "</p>" +
                            "</li>"
                        );
                    }
                }
                console.log(data);
                $('.issue').empty();
                $('.issue').append("" +
                    "<div class='card'>" +
                    "<div class='card-header'>" +
                    "<h6 class='pull-left'>" +
                    // "<span class='"+ state+"'>"+data.issue.status.name+"</span> "+
                    data.issue.subject +
                    "</h6>" +
                    "</div>" +
                    "<div class='card-body'>" +
                    "<div class='row col-12'>" +
                    "<p class='text-left'>" +
                    "<strong>" + data.issue.description + "</strong>" +
                    "</p>" +
                    "</div>" +
                    "<div class='row col-12'>" +
                    "<div class='card'>" +
                    "<ul class='list-group list-group-flush'>" +
                    notes +
                    "</ul>" +
                    "</div>" +
                    "</div>" +
                    "<a class='btn btn-primary pull-right mt-4' href=" + "https://redmine.umb.sk/issues/" + data.issue.id + ">Otvoriť v redmine</a>" +
                    "</div>" +
                    "</div>");
            }).fail(function () {
                $('.issue').empty();
                $('.issue').append("" +
                    "<div class='card'>" +
                    "<div class='card-header'>" +
                    "<h6> Ticket v redmine bol odstránený</h6>" +
                    "<h1> :( </h1>" +
                    "</div>" +
                    "</div>");
            })
            ;
        }

        $('.datetimepicker-input').each(function () {
            let datepickerId = $(this).parent().attr('id');
            $('#' + datepickerId).datetimepicker({
                locale: 'sk',
                format: 'DD. MM. YYYY',
            });
        });

        function sendReaded(emailId) {
            $.get('/helpdesk/ajax/readed/' + emailId);
        }

        ModuleComponents.init();
    }

    return {
        init: function (params) {
            init(params);
        }
    };
}
();

module.exports = function (){
    function init(params){
        $(params[0]).on('show.bs.modal', function (e){
            console.log(e.relatedTarget.dataset.action);
            $('#modal-danger-action-btn').on('click', function (){
                window.location.href = e.relatedTarget.dataset.action;
            });
        });
        ModuleDatatable.init();
    }
    return {
        init: function (params){
            init(params);
        },
    };
}();
module.exports = function () {
    function init(){
        let $ai = '';
        let counterTo = 1;
        let counterBcc = 0;
        let attachmentCounter = 0;

        $('#ai').on('click', () => {
            let $emailBody = document.getElementById('ticket_response_message');
            let question = {
                question: $emailBody.value
            };
            $.ajax({
                type: 'POST',
                url: '/helpdesk/ajax/get/ai/response',
                // url: 'http://127.0.0.1:5000/',
                data: JSON.stringify(question),
                contentType: 'application/json charset=utf-8',
                complete: function (data) {
                    console.log(data);
                    if($ai !== ''){
                        $ai = '';
                    }
                    $ai = data.responseJSON;
                    showAiModal();
                }
            });
        });
        $("#addEmailToFcn").on('click', ()=>{
            let emailTo = $('#addEmailTo');
            if (emailTo.val() !== '') {//&& $('#addEmailTo').checkValidity()
                let $to = $('.to');
                $to.append('<li class="pt-2" style="list-style-type: none"><div><div class="row">\n' +
                    '<div class="row-to pl-0 col-md-9 pr-0"><input type="email" id="ticket_response_to_' + counterTo + '" name="ticket_response[to][' + counterTo + ']" required="required" class="email-box form-control" readonly="readonly" value="' + emailTo.val() + '"></div>\n' +
                    '<button type="button" class="col-md-2 ml-0 btn btn-danger text-white removeRow" ><i class="fa fa-minus"></i></button>\n' +
                    '</div></div></li>');
                counterTo++;
                emailTo.val('');
            }
        });
        $(document).on('click', '.removeRow', function () {
            $(this).parent().remove();
        });

       $('#addEmailBccFcn').on('click',function () {
           let emailBcc = $('#addEmailBcc');
           if (emailBcc.val() !== '') {//&& $('#addEmailTo').checkValidity()
               let $bcc = $('.bcc');
               $bcc.append('<li class="pt-2 mb-4" style="list-style-type: none"><div><div class="row pl-0">\n' +
                   '<div class="row-to pl-0 col-md-9 pr-0"><input type="email" id="ticket_response_bcc_' + counterBcc + '" name="ticket_response[bcc][' + counterBcc + ']" required="required" class="email-box form-control" readonly="readonly" value="' + emailBcc.val() + '"></div>\n' +
                   '<button class="col-md-2 ml-0 btn btn-danger text-white removeRow"><i class="fa fa-minus"></i></button>\n' +
                   '</div></div></li>');
               counterBcc++;
               emailBcc.val('');
           }
       });

        // $('#ticket_response_submit').on('click', () => {
        //     console.log("Odoslat");
        //     document.forms['ticket_response'].submit();
        // });

        $('#addAttachment').on('click', function () {
            let prototype = $('div.attachment').data('prototype');
            let $newForm = prototype.replace(/__name__/g, attachmentCounter);
            $('#ticket_response_attachments').append('<div class="row"><div class="pl-0 col-md-9 pr-0">' + $newForm + '</div><button class="col-md-2 ml-0 btn btn-danger text-white removeRow"><i class="fa fa-minus"></i></button></div>');
            attachmentCounter++;
        });

        $(document).on('change', '.custom-file-input', function (event) {
            $(this).next('.custom-file-label').html(event.target.files[0].name);
            console.log(event.target.files[0].name);
        });

        function newMessage() {
            let $textArea = $(".message");
            $textArea.empty().append('<p>' + $ai + '</p>');
            // console.log($message);
            // $message = $message.replace(/<div class='message'>(.*)<\/div>/g, '<div class=\'message\'><p>' + $ai + '</p><hr></div>');
            // $textArea.val($message);
            // location.reload();
        }
        function showAiModal() {
            console.log($ai);
            $('.aiResponse').empty();
            $('.aiResponse').append('<p>' + $ai + '</p>');
            $('#aiResponse').modal('show');
        }

        $('#response').on('change',()=>{
            let str = "";
            $( "#response option:selected" ).each(function() {
                str += $( this ).data('prototype');
            });
            $ai= str;
            newMessage();
        });

        $(document).ready(function (){
           $( '#ticket_response_message' ).summernote();
        });
        $("#newMessage").on('click', function () {
           newMessage();
        });
        ModuleComponents.init();
    }
    return {
        init: function (params) {
            init(params);
        }
    };
}();

module.exports = function () {
    function init(params){
        Logger.debug('ComponentDataTables init');
        Logger.debug(params);

        $('.datatables').DataTable({
            "language": {
                "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Slovak.json"
            },
            "iDisplayLength": 25,
            responsive: true,
            pagingType:'simple'

        });
    }
    return {
        init: function (params) {
            init(params);
        }
    };
}();

import '../css/app.scss';
import '../theme/downloaded/summernote/summernote-bs4.css';
import "../theme/downloaded/spectrum/spectrum.min.css";
//import 'Hinclude/hinclude';

import '../../public/bundles/tetranzselect2entity/js/select2entity';
require('./initTheme.js');
global.jsTree = require('jstree');
global.ModuleCKEditor = require("./ModuleCKEditor");
global.ModuleResponse = require("./ModuleResponse");
global.ModuleDatatable = require("./ModuleDatatable");
global.ModuleViewTicket = require("./ModuleViewTicket");
global.ModuleStateAccounts = require("./ModuleStateAccounts");
global.ModuleResponses = require("./ModuleResponses");
global.ModuleSearchInMainPage = require("./ModuleSearchInMainPage");

// toto uz su moduly konrektnej aplikacie - nepatria k teme
global.ModuleUsers = require("./ModuleUsers");
global.ModuleComponents = require("./ModuleComponents");
global.ModuleDocuments = require("./ModuleDocuments");
global.ModuleYoutubeVideos = require("./ModuleYoutubeVideos");
global.ModulePasswordStrength = require("./ModulePasswordStrength");
global.ModuleSearch = require("./ModuleSearch");
global.ModuleReadMore = require("./ModuleReadMore");
global.ModuleViewUserTicket = require("./ModuleViewUserTicket");
global.ModuleBootstrapAction = require("./ModuleBootstrapAction");
global.ModuleArticle = require("./ModuleArticle");
global.ModuleTickets = require("./ModuleTickets");

// po nacitanie stranky inicializovat JS podla objektu jsInit
$(document).ready(function() {
    Logger.debug('prism.highlightAll');
    global.Prism.highlightAll();
    // jsInit nastavuje controller cez base template jsInit = {module, method, params}
    if (window.jsInit !== null){
        let initCommand = jsInit.module + '.' + jsInit.method + '(' + JSON.stringify(jsInit.params) + ')';
        //Components.init();
        Logger.debug('Init command: ' + initCommand);
        eval(initCommand);
    }else {
        Logger.debug('Premenna jsInit nie je nastavena');
    }
});



module.exports = function () {
    function init() {
        $("#auto_responses_message").summernote();
        $('#pre_define_response_response').summernote();
        ModuleComponents.init();
    }

    return {
        init: function (param) {
            init();
        }
    };
}();
// Inicializacia CK editora
module.exports = function () {
    function init(imageUploadUrl)  {
        ClassicEditor
            .create( document.querySelector( '.editor' ), {
                removePlugins: [  ],
                heading: {
                    options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        { model: 'heading1', view: 'h2', title: 'Heading 1', class: 'ck-heading_heading1' },
                        { model: 'heading2', view: 'h3', title: 'Heading 2', class: 'ck-heading_heading2' },
                        { model: 'heading3', view: 'h4', title: 'Heading 2', class: 'ck-heading_heading3' },
                        {
                            model: 'callout-danger',
                            view: {
                                name: 'div',
                                classes: 'callout callout-danger'
                            },
                            title: 'Callout danger',
                            class: 'callout callout-danger',

                            // Ak by som potreboval urobti override - It needs to be converted before the standard 'heading2'.
                            // converterPriority: 'high'
                        },
                        {
                            model: 'callout-success',
                            view: {
                                name: 'div',
                                classes: 'callout callout-success'
                            },
                            title: 'Callout success',
                            class: 'callout callout-success',
                        },
                        {
                            model: 'callout-info',
                            view: {
                                name: 'div',
                                classes: 'callout callout-info'
                            },
                            title: 'Callout info',
                            class: 'callout callout-info',
                        }
                    ]
                },
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList',
                        'highlight',
                        'subscript',
                        'superscript',
                        'underline',
                        'strikeThrough',
                        'removeFormat',
                        '|',
                        'indent',
                        'outdent',
                        '|',
                        'code',
                        'codeBlock',
                        'specialCharacters',
                        '|',
                        'imageUpload',
                        'blockQuote',
                        'insertTable',
                        'mediaEmbed',
                        'horizontalLine',
                        '|',
                        'undo',
                        'redo'
                    ]
                },
                language: 'sk',
                image: {
                    toolbar: [
                        'imageTextAlternative',
                        'imageStyle:full',
                        'imageStyle:side'
                    ]
                },
                table: {
                    contentToolbar: [
                        'tableColumn',
                        'tableRow',
                        'mergeTableCells',
                        'tableCellProperties',
                        'tableProperties'
                    ]
                },
                licenseKey: '',
                simpleUpload: {
                    // The URL that the images are uploaded to.
                    uploadUrl: imageUploadUrl,
                }
            } )
            .then( editor => {
                window.editor = editor;
                // po inicializacii editora
                let plugins = ClassicEditor.builtinPlugins.map( plugin => plugin.pluginName );
                Logger.debug('CKEditor plugins:');
                Logger.debug(plugins);
            } )
            .catch( error => {
                Logger.error("Chyba pri inisializacii editora: " +  error );
            } );

    }

    return {
        init: function(imageUploadUrl) {
            init(imageUploadUrl);
        }
    }
}();
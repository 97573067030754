module.exports = function () {
    let fileUploadUrl;

    function init(params) {
        fileUploadUrl = params.fileUploadUrl;
        let documentId = params.documentId;
        let $oembed = document.getElementsByTagName('oembed');

        for (let $i = 0; $i < $oembed.length; $i++) {
            let $url = $oembed[$i].getAttribute('url');
                $url = $url.replace("youtube.com/watch?v=", 'youtube.com/embed/');
                $url = $url.replace("youtu.be/", 'youtube.com/embed/');
            let $embedDiv = $('<div class="embed-responsive embed-responsive-16by9 w-100" />');
            $('<iframe class="embed-responsive-item embed-responsive-16by9" src="'+$url+'" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />').appendTo($embedDiv);
            $embedDiv.appendTo($oembed[$i].parentElement);
        }

        // CKEdit
        fileUploadUrl = fileUploadUrl.replace(/undefined|[0-9]+$/, documentId);
        Logger.debug(`Nova adresa pre upload suborov: ${fileUploadUrl}`);

        $('.ckeditor').each(function (index, value) {
            Logger.debug("creating CKEditor elementId: " + this.id);
            ModuleCKEditor.create(this.id, fileUploadUrl, true);
        });
    }

    return {
        init: function (params) {
            init(params);
        }
    };
}();
import 'jquery';
import 'popper.js';
import 'bootstrap';
import 'select2';
global.moment = require('moment');
require('tempusdominus-bootstrap-4');
import 'fullcalendar';
import 'moment-timezone';
import 'datatables.net-bs4';
import 'datatables.net-select';
import 'datatables.net-select-bs4';
import 'datatables.net-buttons';
import 'datatables.net-buttons-bs4';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4';
// tlacidla treba importovat osobitne
import 'datatables.net-buttons/js/buttons.colVis.js';

global.ClassicEditor = require('../theme/downloaded/CKEditor5-v19/build/ckeditor');
require('../theme/downloaded/summernote/summernote-bs4');
//global.ClassicEditor = require('@ckeditor/ckeditor5-build-classic');

//global.summernote = require('../theme/downloaded/summernote/summernote-bs4');
import '../theme/downloaded/spectrum/spectrum.min';
// import 'vanderlee-colorpicker/jquery.colorpicker';
import '../theme/js/ThemeSidebar';
import '../theme/js/ThemeFileInput';
import '../theme/js/ThemeInputRange';
import '../theme/js/ThemeCard';
global.ThemeEditor = require('../theme/js/ThemeEditor');
global.Toast = require('../theme/js/ThemeToast');
global.Logger = require('../theme/js/Logger.js');
global.Prism = require('prismjs');
import '../theme/downloaded/passtrenght/jquery.passtrength';

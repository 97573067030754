module.exports = function () {
    function init(params) {
        let $oembed = document.getElementsByTagName('oembed');
        for (let $i = 0; $i < $oembed.length; $i++) {
            let $url = $oembed[$i].getAttribute('url');
                $url = $url.replace("youtube.com/watch?v=", 'youtube.com/embed/');
                $url = $url.replace("youtu.be/", 'youtube.com/embed/');
            let $embedDiv = $('<div class="embed-responsive embed-responsive-16by9  w-md-50 w-100" />');
            $('<iframe class="embed-responsive-item embed-responsive-16by9" src="'+$url+'" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />').appendTo($embedDiv);
            $embedDiv.appendTo($oembed[$i].parentElement);
        }
    }
    return {
        init: function (params) {
                init(params);
        }
    };
}();
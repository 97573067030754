module.exports = function () {
    function init(params) {
        if (typeof params !== 'undefined') {
            window.imageUploadUrl = params.imageUploadUrl;
            global.ThemeEditor.init(window.imageUploadUrl);
        }
        $('#datepicker-1').datetimepicker({
            locale: 'sk',
            format: 'DD. MM. YYYY HH:mm:ss'
        });

        $('#datepicker-2').datetimepicker({
            locale: 'sk',
            format: 'DD. MM. YYYY'
        });

        $('#datepicker-3').datetimepicker({
            locale: 'sk',
            format: 'HH:mm:ss'
        });

        $('#datepicker-4').datetimepicker({
            locale: 'sk',
            format: 'DD. MM. YYYY HH:mm:ss',
            inline: true,

        });

        // bez searchu
        $('.select2-0').select2({
            minimumResultsForSearch: -1
        });

        $('#datepickerModal').datetimepicker({
            locale: 'sk',
            format: 'DD. MM. YYYY',
        });

        // $('.select2').select2();
        $('.select2-1').select2();
        $('.modal').on('show.bs.modal', (e) => {
            $('.select2-1').select2({
                dropdownParent: e.target,
            });
            $('.select2entity[data-autostart="true"]').select2entity({
                dropdownParent: e.target
            });
        });

        // Ajax select 2
        $('.select2entity[data-autostart="true"]').select2entity({
            dropdownParent: $('#redmineIssue')
        });

        // tooltips
        $('[data-toggle="tooltip"]').tooltip({boundary: 'window'});
        $("#color-picker").spectrum();

        $('.datatables').DataTable({
            "language": {
                "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Slovak.json"
            },
            responsive: true,
            pagingType: 'simple'

        });

        $('.datatables-history').DataTable({
            "language": {
                "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Slovak.json"
            },
            columnDefs: [{'targets': 0, type: 'date-euro'}],
            order: [[0, "desc"]],
            responsive: true,
            pagingType: 'simple'

        }).moment('DD.MM.YY H:i');

    }

    return {
        init: function (params) {
            init(params);
        }
    };
}();

module.exports = function () {
    let tableTicketsNew;
    let tableTicketsOpen;
    let tableTicketsWaiting;

    function init(params) {
        tableTicketsNew = $("#table-tickets-new").DataTable({
            language: {
                url: "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Slovak.json"
            },
            ajax: {
                url: params.ticketsNewDataUrl,
                dataSrc: 'data',
                type: "GET",
            },
            columnDefs: [
                {orderable: true, targets: 0},
                {orderable: true, targets: 1},
                {orderable: false, targets: 2},
                {orderable: false, targets: 3},
                {orderable: false, targets: 4},
                {orderable: false, targets: 5},
                {orderable: false, targets: 6},
            ],
            order: [[1, 'asc']],
            processing: true,
            serverSide: true,
            responsive: true,
            initComplete: function (settings, json) {
                $('[data-toggle="tooltip"]').tooltip({boundary: 'window'});
            }
        });

        $('#btn-reload-tickets-new').click(function () {
            tableTicketsNew.ajax.reload();
        });

        tableTicketsOpen = $("#table-tickets-open").DataTable({
            language: {
                url: "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Slovak.json"
            },
            ajax: {
                url: params.ticketsOpenDataUrl,
                dataSrc: 'data',
                type: "GET",
            },
            columnDefs: [
                {orderable: true, targets: 0},
                {orderable: true, targets: 1},
                {orderable: false, targets: 2},
                {orderable: false, targets: 3},
                {orderable: false, targets: 4},
                {orderable: false, targets: 5},
                {orderable: false, targets: 6},
            ],
            order: [[1, 'asc']],
            processing: true,
            serverSide: true,
            responsive: true,
            initComplete: function (settings, json) {
                $('[data-toggle="tooltip"]').tooltip({boundary: 'window'});
            }
        });

        $('#btn-reload-tickets-open').click(function () {
            tableTicketsOpen.ajax.reload();
        });

        tableTicketsWaiting = $("#table-tickets-waiting").DataTable({
            language: {
                url: "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Slovak.json"
            },
            ajax: {
                url: params.ticketsWaitingDataUrl,
                dataSrc: 'data',
                type: "GET",
            },
            columnDefs: [
                {orderable: true, targets: 0},
                {orderable: true, targets: 1},
                {orderable: false, targets: 2},
                {orderable: false, targets: 3},
                {orderable: false, targets: 4},
                {orderable: false, targets: 5},
                {orderable: false, targets: 6},
            ],
            order: [[1, 'asc']],
            processing: true,
            serverSide: true,
            responsive: true,
            initComplete: function (settings, json) {
                $('[data-toggle="tooltip"]').tooltip({boundary: 'window'});
            }
        });

        $('#btn-reload-tickets-waiting').click(function () {
            tableTicketsWaiting.ajax.reload();
        });
    }

    return {
        init: function (params) {
            init(params);
        },
    };
}();
module.exports = function () {
    function init() {
        ModuleSearch.init();
        let curHeight = $('.text-read-more').height();
        if (curHeight === 70)
            $('.read').show();
        else
            $('.read').hide();

        $('.read').on('click',() =>{
            let readIcon = $('.read-icon');
            if (readIcon.text() === 'add') {
                readIcon.text("remove");
            } else {
                readIcon.text("add");
            }

            $('.height-read-more').toggleClass("height-read-more-auto");
        });
    }

    return {
        init: function () {
            init();
        }
    }
}();
/**
 * Vseobecne funckie a inicializacie
 */
module.exports = function () {
    let ckEditorInstances = new Map();

    function createCKEditor(elementId, fileUploadUrl, setFocus) {
        let editor = ClassicEditor
            .create(document.querySelector('#' + elementId), {
                //removePlugins: ['MathType'],
                // plugins:[
                //     'ImageInsert'
                // ],
                heading: {
                    options: [
                        {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
                        {model: 'heading1', view: 'h2', title: 'Heading 1', class: 'ck-heading_heading1'},
                        {model: 'heading2', view: 'h3', title: 'Heading 2', class: 'ck-heading_heading2'},
                        {model: 'heading3', view: 'h4', title: 'Heading 3', class: 'ck-heading_heading3'},
                        {
                            model: 'callout-danger',
                            view: {
                                name: 'div',
                                classes: ['callout', 'callout-danger']
                            },
                            title: 'Callout danger',
                            class: 'callout callout-danger',

                            // Ak by som potreboval urobti override - It needs to be converted before the standard 'heading2'.
                            // converterPriority: 'high'
                        },
                        {
                            model: 'callout-info',
                            view: {
                                name: 'div',
                                classes: ['callout', 'callout-info']
                            },
                            title: 'Callout info',
                            class: 'callout callout-info',
                        },
                        {
                            model: 'callout-success',
                            view: {
                                name: 'div',
                                classes: ['callout', 'callout-success']
                            },
                            title: 'Callout success',
                            class: 'callout callout-success',
                        },
                        {
                            model: 'callout-warning',
                            view: {
                                name: 'div',
                                classes: ['callout', 'callout-warning']
                            },
                            title: 'Callout warning',
                            class: 'callout callout-warning',
                        },
                        {
                            model: 'callout-dark',
                            view: {
                                name: 'div',
                                classes: ['callout', 'callout-dark']
                            },
                            title: 'Callout dark',
                            class: 'callout callout-dark',
                        },
                        {
                            model: 'callout-secondary',
                            view: {
                                name: 'div',
                                classes: ['callout', 'callout-secondary']
                            },
                            title: 'Callout secondary',
                            class: 'callout callout-secondary',
                        },
                        {
                            model: 'callout-light',
                            view: {
                                name: 'div',
                                classes: ['callout', 'callout-light']
                            },
                            title: 'Callout light',
                            class: 'callout callout-light',
                        },
                    ]
                },
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList',
                        'highlight',
                        'subscript',
                        'superscript',
                        'underline',
                        'strikeThrough',
                        'removeFormat',
                        '|',
                        'indent',
                        'outdent',
                        'alignment',
                        '|',
                        'code',
                        'codeBlock',
                        'specialCharacters',
                   //     'htmlEmbed',
                        '|',
                        'imageUpload',
                        // 'insertImge',
                        'blockQuote',
                        'insertTable',
                        'mediaEmbed',
                        'horizontalLine',
                        '|',
                        'undo',
                        'redo'
                    ]
                },
                language: 'sk',
                image: {
                    toolbar: [
                        'imageTextAlternative',
                        'imageStyle:full',
                        'imageStyle:side'
                    ]
                },
                table: {
                    contentToolbar: [
                        'tableColumn',
                        'tableRow',
                        'mergeTableCells',
                        'tableCellProperties',
                        'tableProperties'
                    ]
                },
                licenseKey: '',
                config: {
                    allowedConfig: true,
                },
                simpleUpload: {
                    // The URL that the images are uploaded to.
                    uploadUrl: fileUploadUrl,
                },
                minify: true,
            })
            .then(editor => {
                // po inicializacii editora
                let plugins = ClassicEditor.builtinPlugins.map(plugin => plugin.pluginName);
                Logger.debug(`CKEditor ${elementId}, uloadUrl: ${fileUploadUrl}, plugins:`);
                Logger.debug(plugins);
                // odlozim si instanciu pod id elementu
                ckEditorInstances.set(elementId, editor);

                // toto je take nudzove riesenie listenera pre upload suboru
                editor.model.document.on('change', () => {
                    const changes = editor.model.document.differ.getChanges();
                    for (const entry of changes) {
                        if (entry.attributeKey === 'src') {
                            ModuleDocuments.afterFileUpload(entry.attributeNewValue);
                        }
                    }
                });
                editor.ui.view.editable.element.style.height = '500px';

                if (setFocus) {
                    Logger.debug('Setting focus ...');
                    editor.editing.view.focus();
                }
            })
            .catch(error => {
                Logger.error("Chyba pri inicializacii editora: " + error);
            });

        btnGodTextMode();
    }

    function btnGodTextMode() {
        $('.btn-god-text-mode').on('click', (e) => {
            e.preventDefault();
            let editor =  $('.ckeditor');
            for (let i=0; i<editor.length;i++){
                let test = editor[i];
                console.log(test);
                // test.toggleClass('showTextArea');
                test.toggle('slow');
                // editor[i].toggleClass('showTextArea');
                // if (editor[i].classList.contains('showTextArea')){
                //     editor[i].removeClass('showTextArea');
                // }else{
                //     editor[i].addClass('showTextArea');
                // }
            }

        });
    }

    return {
        create: function (elementId, fileUploadUrl, setFocus) {
            createCKEditor(elementId, fileUploadUrl, setFocus);
        },
        getEditors: function () {
            return ckEditorInstances;
        }
    };
}();

module.exports = function (){
    function init(){
        document.getElementById('search').value='';
        const search = document.querySelector('input');
        search.addEventListener('input', updateValue);
    }
    function updateValue(e){
        let searchText = e.target.value.toLowerCase();
        $('.card-filter').filter(function (){
           $(this).toggle($(this).text().toLowerCase().indexOf(searchText) > - 1);
        });
        let howTo = document.getElementsByClassName('card-filter');
    }
    return {
      init: function (){
         return init();
      }
    };
}();

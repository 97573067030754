module.exports = function (){
    let tableUsers;

    function init(params){
        tableUsers = $("#table-users").DataTable({
            language: {
                url: "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Slovak.json"
            },
            ajax: {
                url: params.userDataUrl,
                dataSrc: 'data',
                type: "GET",
            },
            processing: false,
            serverSide: false,
            responsive: true,
            initComplete: function(settings, json) {
                $('[data-toggle="tooltip"]').tooltip({ boundary: 'window' });
            }
        });

        $('#btn-reload-users').click(function(){
            tableUsers.ajax.reload();
        });
    }

    return {
        init: function (params){
            init(params);
        },
    };
}();
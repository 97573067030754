module.exports = function () {
    function init(params) {
        $(document).ready(function () {
            let targetSystems = $('.targetSystems');
            for (let i = 0; i < targetSystems.length; i++) {
                $.get('/user/ajax/get_account_status/' + targetSystems[i].dataset.value, function (data) {
                    let $tableRow = $('.' + data['result'][0]).children();
                    $($tableRow[1]).empty();
                    let color;
                    let alternativeMessage = '<div class="alert alert-info">Informácia o stave nie je k dispozícií.</div>';
                    if (data['result'][1][2].length === 0) {
                        color = 'success';
                    } else {
                        color = 'danger';
                    }
                    if (data['result'][1][1] === 'offline') {
                        color = 'info';
                    }
                    let accountStatus = '<div class="alert alert-' + color + '">' + data['result'][1][0] + '</div>';
                    if (data['result'][1][0] === ''){
                        $tableRow[1].innerHTML += alternativeMessage;
                    }else{
                        $tableRow[1].innerHTML += accountStatus;
                    }
                });
            }
        });
    }
    return{
        init: function (params) {
            init(params);
        }
    }
}();
module.exports = function () {
    /**
     * Url odkial sa nacitaju data pre zobrazenie stromu dokumentov
     * @type {string}
     */
    let documentTreeUrl = '';

    /**
     * Url odkial sa nacitaju data pre zobrazenie stromu tagov
     * @type {string}
     */
    let tagTreeUrl = '';

    /**
     * Adresa kam sa maju nahravat dokumenty cez ajax
     * @type {string}
     */
    let fileUploadUrl = '';

    /**
     * Modry pas so spinnerom, ktory sa zobrazuje pri nahravani formularov adt
     * @type {string}
     */
    let processingHtml = '<div class="alert alert-info text-center"><div class="spinner-border text-white" role="status">' +
        '<span class="sr-only">Prosím čakajte...</span>\n' +
        '</div></div>';

    /**
     * Samotny spinner, ktory je napriklad pouzity pri cakani na vymazanie suboru
     * @type {string}
     */
    let spinnerHtml = '<div class="text-center p-2"><div class="spinner-border text-info" role="status"></div></div>';


    /**
     * Instancia DataTables pre zobrazenie zoznamu dokumentov.
     * @type DataTable
     */
    let tableDocumentList = null;

    /**
     * Dekodovat node ID stromu dokumentov
     * @param treeId
     * @return object {documentId, categoryId, type}
     */
    function decodeTreeId(treeId) {
        let regexpDocument = /c(\d+)-d(\d+)/i;
        let regexpCategory = /c(\d+)/i;
        let regexTag = /c(\d)/i;
        let matchesDocument = treeId.match(regexpDocument);
        let matchesCategory = treeId.match(regexpCategory);
        let matchesTag = treeId.match(regexTag);
        let documentId = null;
        let categoryId = null;
        let tagId = null;
        let nodeType = null;

        if (matchesDocument) {
            nodeType = 'document';
            categoryId = matchesDocument[1];
            documentId = matchesDocument[2];
        } else if (matchesCategory) {
            nodeType = 'category';
            categoryId = matchesCategory[1];
        } else if (matchesTag) {
            tagId = matchesTag[1];
        }

        Logger.debug(`Tree D&D[${treeId}]: ${documentId}, ${categoryId}, ${nodeType}`);

        return {
            documentId: documentId,
            categoryId: categoryId,
            tagId: tagId,
            type: nodeType
        };
    }

    /**
     * Zostavit kontextove menu pre strom dokumentov
     * @returns {Object}
     */
    function getDocumentTreeContextMenu() {
        return {
            'items': {
                'createDocument': {
                    'label': 'Create Document',
                    'title': 'Vytvoriť nový dokument',
                    'icon': 'fa fa-plus',
                    'action': function (data) {
                        let clickedNode = $.jstree.reference(data.reference).get_node(data.reference);
                        let treeId = clickedNode.id;
                        let node = decodeTreeId(treeId);
                        loadDocument(0, node.categoryId);
                    }
                },
                'createCategory': {
                    'label': 'Create Category',
                    'title': 'Vytvoriť novú kategóriu',
                    'icon': 'fa fa-plus',
                    'action': function (data) {
                        let clickedNode = $.jstree.reference(data.reference).get_node(data.reference);
                        let treeId = clickedNode.id;
                        let node = decodeTreeId(treeId);
                        loadCategory(0, node.categoryId);
                    }
                },
                'delete': {
                    'label': 'Delete',
                    'title': 'Vymazať dokument alebo kategóriu',
                    'icon': 'fa fa-trash',
                    'action': function (data) {
                        let clickedNode = $.jstree.reference(data.reference).get_node(data.reference);
                        let documentId = clickedNode.id;

                        deleteDocument(documentId, clickedNode.text);
                    }
                }
            }
        };
    }

    /**
     * Vymazat dokument
     * @param documentId
     * @param nameHtml
     */
    function deleteDocument(documentId, nameHtml) {
        if (nameHtml === undefined) {
            nameHtml = `#${documentId}`;
        }
        // odstranim HTML tagy
        let name = nameHtml.replace(/(<([^>]+)>)/ig, "");

        if (!window.confirm('Naozaj chcete vymazať dokument "' + name + '"?')) {
            return false;
        }

        $('#edit-form').html(processingHtml);
        $.get('/document/ajax/delete/' + documentId, function (result) {
            // objekt bol vymazany
            $('#edit-form').html(result.data);
        }).done(function () {
            refreshTree(true);
        }).fail(function (jqXhr) {
            Toast.error('Dokument sa nepodarilo vymazať: ' + jqXhr.responseText);
        });
    }

    /**
     * Zostavit kontextove menu pre strom tagov
     * @returns {Object}
     */
    function getTagTreeContextMenu() {
        return {
            'items': {
                'createDocument': {
                    'label': 'Create Tag',
                    'title': 'Vytvoriť nový tag',
                    'icon': 'fa fa-plus',
                    'action': function (data) {
                        let clickedNode = $.jstree.reference(data.reference).get_node(data.reference);
                        let treeId = clickedNode.id;
                        let node = decodeTreeId(treeId);
                        loadTag(0, node.tagId);
                    }
                },
                'delete': {
                    'label': 'Delete',
                    'title': 'Vymazať tag',
                    'icon': 'fa fa-trash',
                    'action': function (data) {
                        let clickedNode = $.jstree.reference(data.reference).get_node(data.reference);
                        let treeId = clickedNode.id;

                        if (!window.confirm('Naozaj vymazať objekt "' + clickedNode.text + '"?')) {
                            return false;
                        }
                        console.log(treeId);
                        // $('#edit-form').html(processingHtml);
                        $.get('/document/ajax/delete/' + treeId, function (result) {
                            // objekt bol vymazany
                            // $('#edit-form').html('');
                            // loadDocument(0);
                        }).done(function () {
                            refreshTree(true);
                        }).fail(function (jqXhr) {
                            Toast.error('Pri mazaní udalosti došlo k chybe: ' + jqXhr.responseText);
                        });
                    }
                }
            }
        };
    }

    /**
     * Obnovit oba stromy
     * @param skipLoading Nezobrazovat animaciu pri nacitavani
     */
    function refreshTree(skipLoading) {
        if (skipLoading === undefined) {
            skipLoading = false;
        }

        $('#document-tree').jstree(true).refresh(skipLoading);
        $('#document-tag-tree').jstree(true).refresh(skipLoading);
    }

    /**
     * Obnovit strom dokumentov
     * @param skipLoading
     */
    function refreshDocumentTree(skipLoading) {
        if (skipLoading === undefined) {
            skipLoading = false;
        }
        $('#document-tree').jstree(true).refresh(skipLoading);
    }

    /**
     * Obnovit strom tagov
     * @param skipLoading
     */
    function refreshTagTree(skipLoading) {
        if (skipLoading === undefined) {
            skipLoading = false;
        }
        $('#document-tag-tree').jstree(true).refresh(skipLoading);
    }

    /**
     * Inicializovat strom dokumentov
     */
    function initDocumentTree() {
        $('#document-tree')
            .jstree({
                'core': {
                    'animation': 0,
                    'check_callback': true,
                    'themes': {
                        'variant': 'large',
                        'dots': true,
                        'stripes': false
                    },
                    'data': {
                        'url': function (node) {
                            return documentTreeUrl;
                        },
                        'data': function (node) {
                            return {'id': node.id};
                        }
                    },
                    'multiple': false,
                    'error': function (err) {
                        console.log(err)
                    },
                },
                'types': {
                    'document': {
                        'icon': 'fa fa-file',
                        'valid_children': []
                    },
                    'category': {
                        'icon': 'fa fa-folder',
                        'valid_children': ['category', 'document']
                    }
                },
                'contextmenu': getDocumentTreeContextMenu(),
                'debug': function (message) {
                    alert("message");
                    Logger.warn("message")
                },
                'plugins': [
                    'contextmenu', 'dnd', 'search', 'html_data',
                    'state', 'types'
                ]
            })
            // nepouzijem event select_node, lebo ten sa vola aj pri refreshi a ja chcem len pri clicku spracovat event
            .on('activate_node.jstree', function (e, data) {
                documentTreeNodeClick(e, data);
            })
            .bind('move_node.jstree', function (e, data) {
                let sourceNode = decodeTreeId(data.node.id);
                let targetNode = decodeTreeId(data.parent);
                let newCategoryId = targetNode.categoryId;

                if (sourceNode.type === 'document') {
                    let documentId = sourceNode.documentId;
                    moveDocument(documentId, newCategoryId, data.position);
                } else {
                    let categoryId = sourceNode.categoryId;
                    moveCategory(categoryId, newCategoryId, data.position);
                }

            });
    }

    /**
     * Inicializovat strom tagov
     */
    function initTagTree() {
        $('#document-tag-tree')
            .jstree({
                'core': {
                    'animation': 0,
                    'check_callback': true,
                    'themes': {
                        'variant': 'large',
                        'dots': true,
                        'stripes': false
                    },
                    'data': {
                        'url': function (node) {
                            return tagTreeUrl;
                        },
                        'data': function (node) {
                            return {'id': node.id};
                        }
                    },
                    'multiple': false
                },
                'contextmenu': getTagTreeContextMenu(),
                'plugins': [
                    'contextmenu',
                    'dnd',
                    'state'
                ]
            })
            // nepouzijem event select_node, lebo ten sa vola aj pri refreshi a ja chcem len pri clicku spracovat event
            .on('activate_node.jstree', function (e, data) {
                tagTreeNodeClick(e, data);
            })
            .bind("move_node.jstree", function (e, data) {
                // TODO: presun tagu
                let sourceNode = data.node.id;
                let targetNode = data.parent;
                let newCategoryId = targetNode.categoryId;
                moveTag(sourceNode, targetNode, data.position);
                Logger.debug(`Move tag: ${sourceNode} - ${targetNode}:${data.position}`);
            });
    }

    /**
     * Presunut dokument do novej kategorie alebo na novu poziciu v ramci kategorie
     * @param documentId
     * @param newCategoryId
     * @param newPosition
     */
    function moveDocument(documentId, newCategoryId, newPosition) {
        $.post({
            url: '/document/moveDocument',
            data: {
                documentId: documentId,
                newCategoryId: newCategoryId,
                newPosition: newPosition
            },
            async: true
        }).done(function (result) {
            Toast.info('Dokument bol presunutý.');
        }).fail(function (result) {
            Toast.error('Dokument sa nepodarilo presunúť: ' + result.responseText);
        });
    }

    /**
     * Presunut kategoriu do inej kategorie alebo na inu poziciu
     * @param categoryId
     * @param newCategoryId
     * @param newPosition
     */
    function moveCategory(categoryId, newCategoryId, newPosition) {
        $.post({
            url: '/document/moveCategory',
            data: {
                categoryId: categoryId,
                newCategoryId: newCategoryId,
                newPosition: newPosition
            },
            async: true
        }).done(function (result) {
            Toast.info('Kategória bola presunutá.');
        }).fail(function (result) {
            Toast.error('Kategóriu sa nepodarilo presunúť: ' + result.responseText);
        });
    }

    /**
     * Presunut tag pod iny tag
     * @param tagId
     * @param newParentTagId
     * @param newPosition
     */
    function moveTag(tagId, newParentTagId, newPosition) {
        $.post({
            url: '/document/moveTag',
            data: {
                tagId: tagId,
                newTagId: newParentTagId,
                newPosition: newPosition
            },
            async: true
        }).done(function (result) {
            Toast.info('Tag bol presunutý.');
        }).fail(function (result) {
            Toast.error('Tag sa nepodarilo presunúť: ' + result.responseText);
        });
    }

    /**
     * Klik na uzol stromu tagov
     * @param e
     * @param data
     */
    function tagTreeNodeClick(e, data) {
        let tagId = data.node.id;
        loadTag(tagId);
    }

    /**
     * Klik na uzol stromu dokumentov
     * @param e
     * @param data
     */
    function documentTreeNodeClick(e, data) {
        let nodeId = data.node.id;

        let node = decodeTreeId(nodeId);
        // ak sme klikli na lave tlacidlo
        if (data.event.originalEvent.button === 0) {
            if (node.type === 'document') {
                loadDocument(node.documentId, node.categoryId);
            } else if (node.type === 'category') {
                loadCategory(node.categoryId);
            } else {
                window.alert('Chybne ID uzla stromu: ' + nodeId);
            }
        }
    }

    /**
     * Nacitat formular pre upravu dokumentu
     * @param documentId
     * @param categoryId
     */
    function loadDocument(documentId, categoryId) {
        $('#edit-form').html(processingHtml);

        if (categoryId === undefined) {
            categoryId = 0;
        }

        let $request = $.ajax({
            url: '/document/edit/' + documentId + '/' + categoryId,
            type: 'GET',
            async: true
        });

        $request.done(function (formHtml) {
            $('#edit-form').html(formHtml);
            initDocumentForm();
            if (documentId === 0) {
                refreshTree(true);
            }
        }).fail(function (jqXhr) {
            $('#edit-form').html(jqXhr.responseText);
        });
    }

    /**
     * Inicializovat zoznam (tabulku) dokumentov
     */
    function initDocumentList() {
        tableDocumentList = $('#table-documents').dataTable({
            language: {
                "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Slovak.json"
            },
            select: {
                style: 'os'
            },
            responsive: true,
            autoWidth: false,
            pagingType: 'simple',
            columnDefs: [
                {responsivePriority: 1, targets: 0},
                {responsivePriority: 2, targets: -1}
            ]
        });

        $('.select2').select2();

        $('a[data-action="load-document"]').on('click', function () {
            let documentId = $(this).attr('data-id');
            Logger.debug('Klik na load document #' + documentId);

            loadDocument(documentId);
        });

        let reloadUrl = $('a[data-action="add-document-tags"]').attr('data-url');
        Logger.debug('Refresh URL: ' + decodeURIComponent(reloadUrl));

        $('a[data-action="add-document-tags"]').on('click', function () {
            Logger.debug('Add-document-tags');
            changeDocumentTags('add');
            reloadDocumentListContainer(reloadUrl);
        });

        $('a[data-action="remove-document-tags"]').on('click', function () {
            Logger.debug('Remove-document-tags');
            changeDocumentTags('remove');
            reloadDocumentListContainer(reloadUrl);
        });

        // tooltips
        $('[data-toggle="tooltip"]').tooltip({boundary: 'window'});
    }

    /**
     * Znovunacitat tabulku s dokumentami s pouzitim definovanej URL
     */
    function reloadDocumentListContainer(url) {
        $.ajax({
                url: url,
            }
        ).done(function (htmlContent) {
            $('#document-list-container').replaceWith(htmlContent);
            initDocumentList();
        }).fail(function (jqXhr) {
            $('#document-list-container').html(jqXhr.responseText);
        });
    }

    /**
     * Akcia, ktora sa vykona pri kliknuti na tlacidlo hromadneho pridania tagov
     * @param changeType
     */
    function changeDocumentTags(changeType) {
        let tags = [];
        let documents = [];

        $('#bulk-tag-change :selected').each(function() {
            tags.push($(this).val());
        });

        let rowData = tableDocumentList.api().rows('.selected').data(); //.map(function(val, inx) {return val[0];});
        //Logger.debug(rowData);

        for (let i=0; i<rowData.length; i++) {
            documents.push(rowData[i][0]);
         }

        Logger.debug(tags);
        Logger.debug(documents);

        let url;

        switch (changeType) {
            case 'add':
                url = '/document/addDocumentTags';
                break;
            case 'remove':
                url = '/document/removeDocumentTags';
                break;
            default:
                alert("Neznáma operácia: " + changeType);
                return;
        }

        $('#document-list-container').html(processingHtml);

        $.ajax({
            url: url,
            data: {
                documents: documents,
                tags: tags
            },
            type: 'GET',
            async: true
        }).done(function (response) {
            Logger.debug(response);
            Toast.info(response);
        }).fail(function (jqXhr) {
            Logger.error(jqXhr.responseText);
            Toast.error(jqXhr.responseText);
        });
    }

    /**
     * Inicializovat zoznam (tabulku) kategorii
     */
    function initCategoryList() {
        $('#table-categories').dataTable({
            language: {
                "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Slovak.json"
            },
            responsive: true,
        });

        $('a[data-action="load-category"]').on('click', function () {
            let categoryId = $(this).attr('data-id');
            loadCategory(categoryId);
        });

        // tooltips
        $('[data-toggle="tooltip"]').tooltip({boundary: 'window'});
    }

    /**
     * Nacitat zoznam dokumentov filtrovany podla kategorie, tagu alebo stavu
     * @param property (category, tag, status)
     * @param value
     */
    function loadDocumentList(property, value) {
        $('#edit-form').html(processingHtml);

        let $request = $.ajax({
            url: '/document/list/' + property + '/' + value,
            type: 'GET',
            async: true
        });

        $request.done(function (formHtml) {
            $('#edit-form').html(formHtml);
            initDocumentList();
        }).fail(function (jqXhr) {
            $('#edit-form').html(jqXhr.responseText);
        });
    }

    /**
     * Nacitat zoznam vsetkych kategorii
     * @param property (category, tag, status)
     * @param value
     */
    function loadCategoryList() {
        $('#edit-form').html(processingHtml);

        let $request = $.ajax({
            url: '/document/listCategory',
            type: 'GET',
            async: true
        });

        $request.done(function (formHtml) {
            $('#edit-form').html(formHtml);
            initCategoryList();
        }).fail(function (jqXhr) {
            $('#edit-form').html(jqXhr.responseText);
        });
    }

    /**
     * Nacitat formular pre upravu kategorie
     * @param categoryId
     * @param parentCategoryId
     */
    function loadCategory(categoryId, parentCategoryId) {
        if (parentCategoryId === undefined) {
            parentCategoryId = 0;
        }

        let $request = $.ajax({
            url: '/document/category/' + categoryId + '/' + parentCategoryId + '/1',
            type: 'GET',
            async: true
        });

        $("#edit-form").html(processingHtml);
        $request.done(function (formHtml) {
            $("#edit-form").html(formHtml);
            initCategoryForm();
            initDocumentList();
        }).fail(function (jqXhr) {
            $("#edit-form").html(jqXhr.responseText);
        });
    }

    /**
     * Inicializacia datepickera vratane checkboxu
     * @param $pickerElement jQuery objekt datapickera
     * @param $checkboxElement jQuery objekt checkboxu, ktory ho aktivuje/deaktivuje
     * @param date Nastaveny datum
     */
    function initDatePicker($pickerElement, $checkboxElement, date) {
        $pickerElement.daterangepicker({
            autoApply: false,    // toto by aj tak nefungovalo ked sa voli aj cas
            singleDatePicker: true,
            timePicker: true,
            startDate: date,
            timePickerIncrement: 15,
            timePicker24Hour: true,
            autoUpdateInput: false,
            locale: {format: 'YYYY-MM-DD HH:mm:ss'}
        }, function (start, end, label) {
            if (start.isValid()) {
                $pickerElement.val(start.format('YYYY-MM-DD HH:mm:ss'));
            } else {
                $pickerElement.val('');
            }
        });

        // po kliknuti do datumoveho pola sa automaticky aktivuje
        $pickerElement.click(function () {
            if ($checkboxElement.prop('checked') === false) {
                $checkboxElement.trigger('click');
            }
        });

        // Checkboxy pri datumovych polickach, ktore ich enabluju/disabluju
        $checkboxElement.change(function () {
            if (this.checked) {
                let startTime = moment().format('YYYY-MM-DD HH:00:00');
                $pickerElement.data('daterangepicker').setStartDate(startTime);

                $pickerElement.val(startTime);
                $pickerElement.prop('readonly', false);
            } else {
                $pickerElement.val('');
                $pickerElement.prop('readonly', true);
            }
        });
    }

    /**
     * Inicializacia datepickerov
     * @see http://www.daterangepicker.com/
     * @param startDateTime
     * @param endDateTime
     */
    function initPublishingDatepickers(startDateTime, endDateTime) {
        let $startDatePicker = $('#edit_document_publishingStart');
        let $endDatePicker = $('#edit_document_publishingEnd');
        let $startCheckBox = $('#enablePublishingStart');
        let $endCheckBox = $('#enablePublishingEnd');

        initDatePicker($startDatePicker, $startCheckBox, startDateTime);
        initDatePicker($endDatePicker, $endCheckBox, endDateTime);
    }

    /**
     * Inicializacia formulara pre upravu kategorie
     */
    function initCategoryForm() {
        // Select boxy
        $(".select2").select2();

        initFileButtons();

        // tooltips
        $('[data-toggle="tooltip"]').tooltip({boundary: 'window'});

        // Funkcia, ktora sa aktivuje pri submitnuti formulara
        $("form[name=edit_category]").submit(function () {
            $("#edit-form").html(processingHtml);
            let formData = new FormData(this);

            let categoryId = formData.get('edit_category[id]');
            if (!categoryId) {
                categoryId = '0';
            }
            $.ajax({
                url: '/document/category/' + categoryId + '/0',
                type: 'POST',
                data: formData,
                async: true,
                cache: false,
                contentType: false,
                processData: false
            }).done(function (formHtml) {
                $('#edit-form').html(formHtml);
                initCategoryForm();
                initDocumentList();
                refreshTree(true);
            }).fail(function (jqXhr) {
                $('#edit-form').html(jqXhr.responseText);
            });

            return false;
        });
    }

    /**
     * Funkcia, ktora sa zavola po tom, co sa skonci upload suboru cez CKEditor
     * @param fileUrl
     */
    function afterFileUpload(fileUrl) {
        // zakodovat url obrazku tak aby to slo vlozit do url
        let base64EncodedUrl = btoa(fileUrl);

        // zistit index noveho filecontainera
        let $fileContainers = $('#container-files').children();

        let nextIndex = $fileContainers.length;

        Logger.debug(`Dalsi index file kontainera: ${nextIndex}`);

        let request = $.ajax({
            url: '/document/fileContainer/' + base64EncodedUrl + '/' + nextIndex,
            type: 'GET',
            async: true,
            cache: false,
            contentType: false,
            processData: false
        });

        request.done(function (data) {
            Logger.debug('Pripojit novy kontainer');
            $(data).appendTo('#container-files');
            initFileButtons();
        });

        request.fail(function (data) {
            Toast.error('Pri nacitani file kontainera doslo k chybe: ' + data.responseText);
        });
    }

    /**
     * Definicia funkcie, ktora sa aktivuje pre submitnuti formulara
     */
    function initDocumentFormSubmit() {
        $("form[name=edit_document]").submit(function () {
            $("#edit-form").html(processingHtml);

            // Ak by bolo treba pristupovat k obsahu ckeditora
            /*
            let ckEditors = ModuleCKEditor.getEditors();

            ckEditors.forEach(function(editor, editorId) {
                Logger.debug('Obsah editora ' + editorId)
                Logger.debug(editor.getData());
            });
            */

            let formData = new FormData(this);

            let documentId = formData.get('edit_document[id]');

            if (!documentId) {
                documentId = '0';
            }

            let request = $.ajax({
                url: '/document/edit/' + documentId,
                type: 'POST',
                data: formData,
                async: true,
                cache: false,
                contentType: false,
                processData: false
            });

            request.done(function (documentFormHtml) {
                $('#edit-form').html(documentFormHtml);
                initDocumentForm();
                refreshTree(true);
            });

            request.fail(function (jqXhr) {
                $('#edit-form').html(jqXhr.responseText);
            });

            return false;
        });
    }

    /**
     * Inicializacie tlacitiel na elementoch pre zobrazenie suborov
     */
    function initFileButtons() {
        // tlacidla pre mazanie suborov
        $('a[data-action="delete-file"]').on('click', function () {
            let fileId = $(this).attr('data-id');
            let fileName = $(this).attr('data-name');
            let fileInfoContainer = $(this).parent().parent();

            deleteFile(fileId, fileName, fileInfoContainer);
        });

        // minimalizovanie card (boxov so subormi)
        $('div[data-toggle="card-toggle"]').on('click', function (e) {
            Logger.debug('Toggle file container');
            $(this).parent().children('.card-body').toggle(300);
        });

        // Aby sa vypisovali nazvy soborov do custom file elementu
        $('.custom-file-input').on('change', function (e) {
            let fileNames = [];

            this.files.forEach(function (item) {
                fileNames.push(item.name);
            });

            let nextSibling = e.target.nextElementSibling;
            nextSibling.innerText = fileNames.join(', ');
        });

        // tooltips
        $('[data-toggle="tooltip"]').tooltip({boundary: 'window'});
    }

    /**
     * Inicializacia formulara pre upravu dokumentov
     */
    function initDocumentForm() {
        // podla id dokumentu upravi adresu pre uload suborov
        let documentId = $('input[name="edit_document[id]"]').val();
        Logger.debug(`Inicializacia formulara pre editovanie dokumentu #${documentId}`);

        fileUploadUrl = fileUploadUrl.replace(/undefined|[0-9]+$/, documentId);
        Logger.debug(`Nova adresa pre upload suborov: ${fileUploadUrl}`);

        // CKEdit
        $('.ckeditor').each(function (index, value) {
            Logger.debug("creating CKEditor elementId: " + this.id);
            // ModuleCKEditor.create(this.id, fileUploadUrl);
            ModuleCKEditor.create(this.id, fileUploadUrl);
        });

        // Select boxy
        $(".select2").select2();

        // datumy publikovania
        $('#datepicker-publishingStart').datetimepicker({
            locale: 'sk',
            format: 'DD. MM. YYYY HH:mm:ss'
        });

        $('#datepicker-publishingEnd').datetimepicker({
            locale: 'sk',
            format: 'DD. MM. YYYY HH:mm:ss'
        });

        // inicializovat ovladacie prvky suvisiace so zubormi
        initFileButtons();

        // tooltips
        $('[data-toggle="tooltip"]').tooltip({boundary: 'window'});

        // aktivovat alternativny button pre submit
        $('[data-action="submitDocumentForm"]').on('click', () => {
            $('form[name="edit_document"]').submit();
        });

        // aktivovat alternativny button pre delete
        $('a[data-action="deleteDocument"]').on('click', function () {
            Logger.debug($(this));
            let documentId = $(this).attr('data-id');
            deleteDocument(documentId);
        });

        $('.datatables').DataTable({
            "language": {
                "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Slovak.json"
            },
            responsive: true
        });

        copyBtn();
        // Pripravit funkciu, ktora sa aktivuje pri submitnuti formulara
        initDocumentFormSubmit();
    }

    /**
     * Vymazat subor
     * @param fileId
     * @param fileName
     * @param $fileContainer
     * @returns {boolean}
     */
    function deleteFile(fileId, fileName, $fileContainer) {
        Logger.debug(`Delete file ${fileId}: ${fileName}`);

        if (fileName === undefined) {
            fileName = '';
        }

        if (confirm('Vymazať súbor ' + fileName + '?')) {
            if ($fileContainer !== undefined) {
                $fileContainer.html(spinnerHtml);
            } else {
                $('#edit-form').html(processingHtml);
            }

            let $request = $.ajax({
                url: '/document/deletefile/' + fileId,
                type: 'POST',
                async: true,
                cache: false,
                contentType: false,
                processData: false
            });

            $request.done(function (documentFormHtml) {
                if ($fileContainer !== undefined) {
                    Logger.debug('Subor bol vymazany');
                    $fileContainer.parent().remove();
                } else {
                    $('#edit-form').html(documentFormHtml);
                    initDocumentForm();
                }
            });

            $request.fail(function (jqXhr) {
                if ($fileContainer !== undefined) {
                    Logger.debug('Subor nebol vymazany');
                    $fileContainer.html(jqXhr.responseText);
                } else {
                    Toast.error('Subor sa nepodarilo vymazat: ' + jqXhr.responseText);
                }
            });
        } else {
            return false;
        }
    }

    /**
     * Inicializovat tlacidla na zakladnej sablone
     */
    function initBaseLayoutButtons() {
        $('#input-searchById').on('keypress', function (e) {
            if (e.which == 13) {
                let documentId = $(this).val();
                Logger.debug('Load document ' + documentId);
                loadDocument(documentId, 0);
            }
        });

        $('#btn-category-refresh').on('click', function () {
            refreshDocumentTree();
        });

        $('#btn-tags-refresh').on('click', function () {
            refreshTagTree();
        });

        $('a[data-action="loadDocumentList"]').on('click', function () {
            let status = $(this).attr('data-status');
            loadDocumentList('status', status);
        });

        $('a[data-action="loadCategoryList"]').on('click', function () {
            loadCategoryList();
        });

        $('a[data-action="load-document"]').on('click', function () {
            let documentId = $(this).attr('data-id');
            Logger.debug('Klik na load document #' + documentId);

            loadDocument(documentId);
        });

        // tooltips
        $('[data-toggle="tooltip"]').tooltip({boundary: 'window'});
    }

    /**
     * Nacitat formular pre upravu tagu
     * @param tagId
     * @param parentTagId
     */
    function loadTag(tagId, parentTagId) {
        if (parentTagId === undefined) {
            parentTagId = 0;
        }
        $("#edit-form").html(processingHtml);
        $.get('/document/tag/' + tagId + '/1', function (formHtml) {
            $("#edit-form").html(formHtml);
            initTagForm();
        });
    }

    $('#edit_tag_submit').on('click', function (e) {
        e.preventDefault();
        initTagForm();
    });

    function initTagForm() {
        // Select boxy
        $('.select2').select2({
            theme: 'bootstrap4'
        });


        // Funkcia, ktora sa aktivuje pri submitnuti formulara
        $("form[name=edit_tag]").submit(function () {
            $("#edit-form").html('<div class="alert alert-info text-center"><i class="fa fa-spinner"></i> Saving ... <span id="request-progress"></span></span></div>');
            let formData = new FormData(this);

            let tagId = formData.get('edit_tag[id]');
            if (!tagId) {
                tagId = '0';
            }

            $.ajax({
                url: '/document/tag/' + tagId + '/1',
                type: 'POST',
                data: formData,
                async: true,
                cache: false,
                contentType: false,
                processData: false,
                xhr: function () {
                    var xhr = new window.XMLHttpRequest();
                    // Handle progress
                    //Upload progress
                    xhr.upload.addEventListener('progress', function (evt) {
                        if (evt.lengthComputable) {
                            var percentComplete = evt.loaded / evt.total;
                            $("#request-progress").text('[ ' + Math.round(100 * percentComplete) + '% ]');
                        }
                    }, false);
                    //Download progress
                    xhr.addEventListener('progress', function (evt) {
                        if (evt.lengthComputable) {
                            var percentComplete = evt.loaded / evt.total;
                            $("#request-progress").text('[ ' + Math.round(100 * percentComplete) + '% ]');
                        }
                    }, false);
                    return xhr;
                },
            }).always(function (formHtml) {
                $('#edit-form').html(formHtml);
                initTagForm();
                refreshTree();
            });

            return false;
        });
    }

    function btnInitDocument() {
        $('.btn-init-document').on('click', () => {
            loadDocument(0);
        });
    }

    function btnInitCategory() {
        $('.btn-init-category').on('click', () => {
            loadCategory(0);
        });
    }

    function copyBtn() {
        $("#copy-btn").on("click", (event) => {
            /* Get the text field */
            var copyText = document.getElementById("copy");

            /* Select the text field */
            copyText.select();
            copyText.setSelectionRange(0, 99999); /* For mobile devices */

            /* Copy the text inside the text field */
            document.execCommand("copy");
            Toast.info('Link bol skopírovaný ');
        });
    }

    /**
     * Akcia, ktora sa spusti po nacitani stranky index dokumentov
     * @param params
     */
    function indexAction(params) {
        documentTreeUrl = params.documentTreeUrl;
        tagTreeUrl = params.tagTreeUrl;
        fileUploadUrl = atob(params.fileUploadUrl);
        Logger.debug("ModuleDocuments.indexAction()");
        Logger.debug("File upload URL: " + fileUploadUrl);
        $("#table-documents").DataTable({
            language: {
                "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Slovak.json"
            },
            responsive: true,
            autoWidth: false,
            pagingType: 'simple',
            columnDefs: [
                {responsivePriority: 1, targets: 0},
                {responsivePriority: 2, targets: -1}
            ]
        });
        initBaseLayoutButtons();
        initDocumentTree();
        initTagTree();
        btnInitCategory();
        btnInitDocument();

        if (params.editDocumentId > 0) {
            loadDocument(params.editDocumentId, 0);
        }
    }

    return {
        indexAction: function (params) {
            indexAction(params);
        },
        afterFileUpload: function (fileUrl) {
            afterFileUpload(fileUrl);
        },
        initDocumentForm: function() {
            initDocumentForm();
        }
    };
}();
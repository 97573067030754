module.exports = function () {
    function delay(callback, ms) {
        var timer = 0;
        return function () {
            var context = this, args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                callback.apply(context, args);
            }, ms || 0);
        };
    }

    function init() {
        document.getElementById('search').value = '';
        $('#search').keyup(delay(function (e) {
                if (e.which === 13) {
                    updateValue(e);
                }
                updateValue(e);

            },150));
    }

    function updateValue(e) {
        let searchText = e.target.value.toLowerCase();
        if (searchText.length >= 3) {
            let lang = $("meta[lang]").attr('lang');
            $(document).ajaxSend(function () {
                $('#searchResults').empty();
                $('#searchResults').append(`<div class="mb-2 text-center"><div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
                </div>`);
            });
            $.get('/search/' + searchText, function (result) {
                $('#searchResults').empty();
                if (result.length > 0) {
                    for (let i = 0; i < result.length; i++) {
                        console.log(result[i]);
                        let title = '';
                        let abstract = '';
                        for (let j = 0; j < result[i].translations.length; j++) {
                            let translation = result[i].translations[j];
                            if (translation.language.id === 'sk') {
                                title = translation.title;
                                abstract = translation.abstract;
                            }
                            if (result[i].translations[j].language.id === lang && result[i].translations[j].title) {
                                title = translation.title;
                                abstract = translation.abstract;
                            }
                        }
                        $('#searchResults').append(
                            `<ul style="list-style: none" class="mb-2 pl-0"><div class='card card-filter' style='border-radius: 5px; border-style: solid; border-width: 1px; border-color: #dfdfdf; border-left:${result[i].category.color ? result[i].category.color : '#dfdfdf'} 5px solid'>
                            <a class="card-body text-decoration-none pl-4 pr-4 pt-2 pb-0"
                            href="/article/${lang}/${result[i].id}">
                         <div class="row">
                            <div class="col-12">
                                <h6 class="pl-2">${title}</h6>
                                <p class="pl-2 float-right">${result[i].category.name}</p>
                                <p class="pl-2 text-muted">${abstract ? abstract : ''}</p>
                            </div>
                         </div>
                         </a>
                         </div>
                         </ul>`
                        );
                    }
                } else {
                    $('#searchResults').append(
                        `    <ul></ul>
                            <div class='mb-2 text-center'>
                                    <span class="material-icons homePageIcon">sentiment_dissatisfied</span>
                                    <h6>${lang === 'sk' ? 'Žiadne výsledky' : 'Not found'}</h6>
                            </div>`
                    );
                }
            });
        }
        if (searchText.length === 0) {
            $('#searchResults').empty();
        }
    }

    return {
        init: function () {
            return init();
        }
    };
}();
